<template lang="pug">
	base-icon.icon-lang-ru(
	icon-name="lang-ru"
	width="30"
	height="30"
	viewBox="0 0 512.001 512.001"
	)
		<path d="M512 200.093H0V97.104a8.829 8.829 0 0 1 8.828-8.828h494.345a8.829 8.829 0 0 1 8.828 8.828L512 200.093z" fill="#f5f5f5"/><path d="M503.172 423.725H8.828A8.829 8.829 0 0 1 0 414.897V311.909h512v102.988a8.828 8.828 0 0 1-8.828 8.828z" fill="#ff4b55"/><path fill="#41479b" d="M0 200.091h512v111.81H0z"/>
</template>

<script>
	export default {
		name: 'IconLangRu'
	};
</script>

<style lang="scss" scoped>
</style>
