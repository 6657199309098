<template lang="pug">
	base-icon.icon-lang-ru(
	icon-name="lang-ru"
	width="30"
	height="30"
	viewBox="0 0 512 512"
	)
		<g fill="#f0f0f0">
			<path d="M0 85.337h512v341.326H0z"/>
			<path d="M0 85.337h512V256H0z"/>
		</g>

		<path fill="#d80027" d="M0 256h512v170.663H0z"/>
</template>

<script>
	export default {
		name: 'IconLangRu'
	};
</script>

<style lang="scss" scoped>
</style>
