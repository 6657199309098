<template lang="pug">
	base-icon.icon-ex-pi(
	icon-name="ex-pi"
	:width="null"
	height="40"
	viewBox="0 0 121.7 56.6"
	)
		<path fill="none" d="M0 0h121.7v56.6H0z"/><path fill="#FF9A00" d="M65 0h56.7v56.6H65z"/><path fill="#EB6E19" d="M65 56.6h56.7V0z"/><path fill="#FFF" d="M99.1 24h3.1v13.2h-3.1zm1.6-5.6c-1.2 0-2.2 1-2.2 2.2s1 2.2 2.2 2.2 2.2-1 2.2-2.2c0-1.2-1-2.2-2.2-2.2zM95 19.9c-1.2-1.1-2.9-1.7-5-1.7h-7V37h3.7v-6.8H90c2.1 0 3.8-.5 5-1.6s1.8-2.5 1.8-4.3c0-1.8-.6-3.3-1.8-4.4zm-2.6 6.6c-.5.6-1.3.9-2.3.9h-3.3v-6.2h3.3c1 0 1.8.3 2.3.9s.8 1.3.8 2.2c0 .9-.3 1.6-.8 2.2z"/><path d="M16.6 37.1v-19h12.5v3.3h-8.8v4.5h7.5v3.3h-7.5v4.6h8.8v3.3H16.6zm27.6 0l-3.7-6.6-3.7 6.6h-4.2l5.9-9.7-5.5-9.3h4.2l3.3 6.1 3.3-6.1H48l-5.5 9.3 5.9 9.7h-4.2z" fill="#191817"/>
</template>

<script>
	export default {
		name: 'IconExPi'
	};
</script>

<style lang="scss" scoped>
</style>
