<template lang="pug">
	.base-button(
	:is="tag"
	v-on="$listeners"
	)
		span.icon
			slot(name="icon")

		span.label
			slot
</template>

<script>
	export default {
		name: 'BaseButton',
		props: {
			tag: {
				type: String,
				default: 'button'
			}
		}
	}
</script>

<style lang="scss">
	:root {
		--base-button_-_transition-property: color,
		border-color,
		background-color,
		box-shadow;
		--base-button_-_transition-duration: 0.2s;
		--base-button_-_transition-timing-function: ease;
	}
</style>

<style lang="scss" scoped>
	.base-button {
		@include reset;

		display: inline-flex;
		vertical-align: top;
		justify-content: center;
		align-items: center;
		max-width: 100%;
		line-height: 1.25;
		text-align: center;
		overflow: hidden;
		white-space: nowrap;
		user-select: none;
		transition-property: var(--base-button_-_transition-property);
		transition-duration: var(--base-button_-_transition-duration);
		transition-timing-function: var(--base-button_-_transition-timing-function);

		&:not(:disabled) {
			cursor: pointer;
		}
	}

	.icon {
		display: block;
		flex-shrink: 0;
	}

	.label {
		display: block;
		overflow: hidden;
		text-overflow: ellipsis;
	}
</style>
