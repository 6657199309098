<template lang="pug">
	base-icon.icon-crypto-pi(
	icon-name="crypto-pi"
	:width="null"
	height="40"
	viewBox="0 0 191.8 56.6"
	)
		<path fill="none" d="M0 0h191.8v56.6H0z"/><path fill="#FF9A00" d="M135.2 0h56.6v56.6h-56.6z"/><path fill="#EB6E19" d="M135.2 56.6h56.6V0z"/><path fill="#181716" d="M23.6 21.2c1.8 0 2.8 1.1 3.2 2.7h3.8c-.7-4-3.4-6-6.9-6-2.2 0-3.8.7-5.1 2-1.9 1.9-1.9 4-1.9 7.6s0 5.7 1.9 7.6c1.3 1.3 2.9 2 5.1 2 3.5 0 6.2-2 6.9-6h-3.7c-.4 1.5-1.4 2.7-3.2 2.7-1 0-1.8-.4-2.4-1-.7-.8-.9-1.7-.9-5.3s.2-4.5.9-5.3c.4-.6 1.3-1 2.3-1zm25.2 2.6c0-3.2-2.3-5.8-6.1-5.8h-7.4v19H39v-7.5h2.7l3.7 7.5h4.3l-4.2-8.1c1.7-.8 3.3-2.4 3.3-5.1zm-6.4 2.5H39v-5h3.5c1.6 0 2.7 1 2.7 2.5-.1 1.5-1.1 2.5-2.8 2.5zm17.1-.6L56 18.1h-4l5.7 11.1V37h3.7v-7.7L67 18.1h-4l-3.5 7.6zm18.7-7.6h-7.3V37h3.7v-7.1h3.6c3.9 0 6.2-2.7 6.2-5.9 0-3.3-2.3-5.9-6.2-5.9zm-.2 8.4h-3.5v-5.2H78c1.7 0 2.7 1.1 2.7 2.6s-1 2.6-2.7 2.6zm9.6-5.1h4.9V37h3.7V21.4h4.9v-3.3H87.6v3.3zm29-1.5c-1.3-1.3-2.9-2-5-2-2.2 0-3.8.7-5.1 2-1.9 1.9-1.9 4-1.9 7.6s0 5.7 1.9 7.6c1.3 1.3 2.9 2 5.1 2s3.8-.7 5-2c1.9-1.9 1.9-4 1.9-7.6.1-3.6 0-5.7-1.9-7.6zm-2.7 12.9c-.5.6-1.4 1-2.4 1s-1.8-.4-2.4-1c-.7-.8-.9-1.7-.9-5.3s.2-4.5.9-5.3c.6-.6 1.4-1 2.4-1s1.8.4 2.4 1c.7.8.9 1.7.9 5.3.1 3.6-.2 4.5-.9 5.3z"/><path fill="#FFF" d="M169.2 24h3.1v13.2h-3.1zm1.6-5.6c-1.2 0-2.2 1-2.2 2.2s1 2.2 2.2 2.2 2.2-1 2.2-2.2-1-2.2-2.2-2.2zm-5.7 1.5c-1.2-1.1-2.9-1.7-5-1.7h-7V37h3.7v-6.8h3.3c2.1 0 3.8-.5 5-1.6s1.8-2.5 1.8-4.3c0-1.8-.6-3.3-1.8-4.4zm-2.6 6.6c-.5.6-1.3.9-2.3.9h-3.3v-6.2h3.3c1 0 1.8.3 2.3.9s.8 1.3.8 2.2c0 .9-.3 1.6-.8 2.2z"/>
</template>

<script>
	export default {
		name: 'IconCryptoPi'
	};
</script>

<style lang="scss" scoped>
</style>
