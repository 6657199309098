<template lang="pug">
	base-icon.icon-capital-pi(
	icon-name="capital-pi"
	:width="null"
	height="40"
	viewBox="0 0 201 56.6"
	)
		<path fill="none" d="M0 0h201v56.6H0z"/><path fill="#D60027" d="M144.4 0H201v56.6h-56.6z"/><path fill="#7E0019" d="M144.4 56.6H201V0z"/><path d="M115.6 37V18.1h3.6v15.6h8.6V37zm-11.1-18.9h-2.9L94.7 37h3.8l1.1-3.3h6.7l1.1 3.3h3.8l-6.7-18.9zm-3.8 12.4l2.4-6.9 2.3 6.9h-4.7zm-11.8-9.1V37h-3.7V21.4h-5v-3.3h13.6v3.3zm-16.8-3.3h3.7V37h-3.7zm-10.8 0H54V37h3.7v-7.1h3.6c3.9 0 6.2-2.7 6.2-5.9 0-3.3-2.3-5.9-6.2-5.9zm-.2 8.4h-3.4v-5.2h3.4c1.7 0 2.7 1.1 2.7 2.6.1 1.6-1 2.6-2.7 2.6zm-18.2-8.4H40L33.2 37H37l1.1-3.3h6.7L46 37h3.8l-6.9-18.9zm-3.7 12.4l2.4-6.9 2.3 6.9h-4.7zm-15.6-9.3c-1 0-1.8.3-2.4 1-.8.8-.9 1.7-.9 5.3 0 3.7.2 4.5.9 5.3.6.7 1.4 1 2.4 1 1.8 0 2.8-1.1 3.2-2.7h3.7c-.6 3.8-3.3 6-7 6-2 0-3.7-.7-5.1-2.1-.8-.8-1.3-1.8-1.5-2.8-.2-1.1-.3-2.6-.3-4.7s.1-3.6.3-4.7c.2-1.1.7-2 1.5-2.8 1.4-1.4 3.1-2.1 5.1-2.1 3.6 0 6.3 2.1 7 6h-3.7c-.4-1.6-1.5-2.7-3.2-2.7z"/><path fill="#FFF" d="M178.5 24h3.1v13.2h-3.1zm1.5-5.6c-1.2 0-2.2 1-2.2 2.2s1 2.2 2.2 2.2 2.2-1 2.2-2.2-.9-2.2-2.2-2.2zm-5.6 1.5c-1.2-1.1-2.9-1.7-5-1.7h-7V37h3.7v-6.8h3.3c2.1 0 3.8-.5 5-1.6s1.8-2.5 1.8-4.3c0-1.8-.6-3.3-1.8-4.4zm-2.7 6.6c-.5.6-1.3.9-2.3.9h-3.3v-6.2h3.3c1 0 1.8.3 2.3.9s.8 1.3.8 2.2c0 .9-.2 1.6-.8 2.2z"/>
</template>

<script>
	export default {
		name: 'IconCapitalPi'
	};
</script>

<style lang="scss" scoped>
</style>
